import React from "react"
import { graphql } from "gatsby"
import { css } from "@emotion/core"
import Layout from "../components/layout"
import Container from "../components/container"

export default ({ data: { site } }) => (
  <Layout site={site} noFooter noSubscribeForm>
    <Container
      css={css`
        text-align: center;
      `}
    >
      <p
        css={css`
          font-style: oblique;
        `}
      >
        A minimalistic blog with a focus on chronic conditions that affect gut
        health. Thanks for dropping by!
      </p>
      <img src={"/images/about-header.jpg"} alt="Logo" />
    </Container>
  </Layout>
)

export const pageQuery = graphql`
  query {
    site {
      ...site
    }
  }
`
